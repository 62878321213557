import { css, DefaultTheme } from 'styled-components';

export const scrollingVisible = css`
  ${({ theme, ...props }) => {
    return css`
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: ${theme.spacing.xxs};
      }
      &::-webkit-scrollbar:horizontal {
        height: 0;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: ${theme.radius.xs};
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    `;
  }};
`;

export const scrollingHorizontalVisible = css`
  ${({ theme, ...props }) => {
    return css`
      &::-webkit-scrollbar {
        -webkit-appearance: none;
      }
      &::-webkit-scrollbar:horizontal {
        -webkit-appearance: none;
        height: ${theme.spacing.xxs};
      }
      &::-webkit-scrollbar-thumb {
        border-radius: ${theme.radius.xs};
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    `;
  }};
`;

export const transitionShowIn = css`
  transform: translateY(-2px);
  transition: transform 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
`;

export type VisibilityBreakpointType = {
  visibility?: {
    min?: keyof DefaultTheme['breakpoint'] & string;
    max?: keyof DefaultTheme['breakpoint'] & string;
    hide?: keyof DefaultTheme['breakpoint'] & string;
  };
};
export const visibilityBreakpoint = css<VisibilityBreakpointType>`
  ${({ theme, visibility, ...props }) => {
    return css`
      ${visibility &&
      css`
        ${visibility.min &&
        css`
          @media screen and (min-width: ${theme.breakpoint[visibility.min]}) {
            display: none !important;
            visibility: hidden !important;
          }
        `};

        ${visibility.max &&
        css`
          display: none;
          visibility: hidden;
          @media screen and (max-width: ${theme.breakpoint[visibility.max]}) {
            display: block;
            visibility: visible;
          }
        `};
        ${visibility.hide &&
        css`
          @media screen and (max-width: ${theme.breakpoint[visibility.hide]}) {
            display: none !important;
            visibility: hidden !important;
          }
        `};
      `}
    `;
  }};
`;

export const inputBaseUtility = css`
  ${({ theme, ...props }) => {
    return css`
      font-size: ${theme.typography.font_size.text_base};
      padding: 0.375rem 0.75rem;
      color: ${theme.color.dark};
      border-width: 2px;
      border-style: solid;
      display: block;
      width: 100%;
      appearance: none;

      // Style
      border-color: ${theme.color.neutral_400};
      border-radius: ${theme.radius.xs};
      outline: 0;

      // Transition
      transition: ${theme.transition.border_color};

      &::placeholder {
        color: ${theme.color.neutral_500};
      }

      &:hover {
        background-color: ${theme.color.neutral_100};
      }

      &:focus {
        border-color: ${theme.color.secondary_500};
        box-shadow: ${theme.box_shadow.sm};
      }

      + label,
      + input,
      + button {
        margin-top: 1rem;
      }
    `;
  }};
`;

export const validationPseudoClassUtility = css`
  ${({ theme, ...props }) => {
    return css`
      &.is-valid {
        border-color: ${theme.color.success_500};
      }
      &.is-invalid {
        border-color: ${theme.color.destructive_400};
      }
    `;
  }};
`;

export const helperTextUtility = css`
  ${({ theme, ...props }) => {
    return css`
      &.is-invalid {
        + span {
          display: block;
        }
      }
    `;
  }};
`;
